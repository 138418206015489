
import { defineComponent } from "vue-demi";
import CommonQuestionnaireTemplatePortfolio from "./portfolio.vue";

export default defineComponent({
  name: "CommonQuestionnaireTemplatePortfolios",
  props: ["target", "course"],
  components: {
    CommonQuestionnaireTemplatePortfolio,
  },
});
