import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfd30b90"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "portfolios"
}
const _hoisted_2 = {
  key: 0,
  class: "page",
  style: {"position":"relative"}
}
const _hoisted_3 = {
  key: 0,
  class: "analysis--header space"
}
const _hoisted_4 = {
  key: 1,
  class: "analysis--header space two"
}
const _hoisted_5 = {
  key: 2,
  class: "description space"
}
const _hoisted_6 = {
  key: 1,
  class: "page",
  style: {"position":"relative"}
}
const _hoisted_7 = {
  key: 0,
  class: "analysis--header blue space"
}
const _hoisted_8 = {
  key: 1,
  class: "analysis--header green space two"
}
const _hoisted_9 = {
  key: 2,
  class: "description space"
}
const _hoisted_10 = {
  key: 2,
  class: "page",
  style: {"position":"relative"}
}
const _hoisted_11 = {
  key: 0,
  class: "analysis--header purple space"
}
const _hoisted_12 = {
  key: 1,
  class: "analysis--header orange space two"
}
const _hoisted_13 = {
  key: 2,
  class: "description space"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_common_questionnaire_template_portfolio = _resolveComponent("common-questionnaire-template-portfolio")!

  return (_ctx.target)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (Number(_ctx.target.status.existing) === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("main", null, [
                (_ctx.course === 'one')
                  ? (_openBlock(), _createElementBlock("section", _hoisted_3, " Анализ существующего портфеля: (Цель " + _toDisplayString(_ctx.target.number) + ") ", 1))
                  : _createCommentVNode("", true),
                (_ctx.course === 'two')
                  ? (_openBlock(), _createElementBlock("section", _hoisted_4, " Стартовый портфель "))
                  : _createCommentVNode("", true),
                (_ctx.course === 'two')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Стартовый портфель — это портфель, который вы составили для первоначальных инвестиций. Сумма этого портфеля = сумма первых вложений, которые вы планируете осуществить. "))
                  : _createCommentVNode("", true),
                _createVNode(_component_common_questionnaire_template_portfolio, {
                  course: _ctx.course,
                  target: _ctx.target,
                  index: 0
                }, null, 8, ["course", "target"])
              ])
            ]))
          : _createCommentVNode("", true),
        (Number(_ctx.target.status.student) === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("main", null, [
                (_ctx.course === 'one')
                  ? (_openBlock(), _createElementBlock("section", _hoisted_7, " Анализ портфеля ученика на всю сумму цели: (Цель " + _toDisplayString(_ctx.target.number) + ") ", 1))
                  : _createCommentVNode("", true),
                (_ctx.course === 'two')
                  ? (_openBlock(), _createElementBlock("section", _hoisted_8, " Портфель на сумму цели "))
                  : _createCommentVNode("", true),
                (_ctx.course === 'two')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Портфель на сумму цели — это портфель, который вы составили для общей (итоговой) суммы вашей цели. Сумма этого портфеля = итоговая сумма вашей финансовой цели. "))
                  : _createCommentVNode("", true),
                _createVNode(_component_common_questionnaire_template_portfolio, {
                  course: _ctx.course,
                  class: _normalizeClass(_ctx.course === 'one' ? 'blue' : 'green'),
                  target: _ctx.target,
                  index: 1
                }, null, 8, ["course", "class", "target"])
              ])
            ]))
          : _createCommentVNode("", true),
        (Number(_ctx.target.status.expert) === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("main", null, [
                (_ctx.course === 'one')
                  ? (_openBlock(), _createElementBlock("section", _hoisted_11, " Портфель, предлагаемый экспертом: (Цель " + _toDisplayString(_ctx.target.number) + ") ", 1))
                  : _createCommentVNode("", true),
                (_ctx.course === 'two')
                  ? (_openBlock(), _createElementBlock("section", _hoisted_12, " Портфель от эксперта "))
                  : _createCommentVNode("", true),
                (_ctx.course === 'two')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, " Портфель эксперта — это то, как может выглядеть инвестиционный портфель на финальную сумму цели с учётом корректировкок и предложений эксперта. Сумма этого портфеля = итоговая сумма вашей финансовой цели. Вы можете использовать этот портфель, как ориентир или один из вариантов, но не как инвестиционную рекомендацию. "))
                  : _createCommentVNode("", true),
                _createVNode(_component_common_questionnaire_template_portfolio, {
                  course: _ctx.course,
                  class: _normalizeClass(_ctx.course === 'one' ? 'purple' : 'orange'),
                  target: _ctx.target,
                  index: 2
                }, null, 8, ["course", "class", "target"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}