
import { defineComponent, PropType } from "vue-demi";
import { Instrument } from "@/interfaces/dto/instrument";
import { dynamicsObject, valueof } from "@/interfaces";
import { COURSES_ENUM } from "@/utils/enums";
import { COURSES_TYPE } from "@/store/commonDatas";

export default defineComponent({
  name: "CommonQuestionnaireTemplatePortfolio",
  props: {
    target: {
      type: Object as PropType<dynamicsObject>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    course: {
      type: String as PropType<valueof<typeof COURSES_ENUM>>,
      required: true
    }
  },
  data() {
    return {
      coreTypes: COURSES_TYPE
    }
  },
  methods: {
    parseLinks(text: string) {
      return `<div>${text.replace(/\b(([\w-]+:\/\/?|www[.])[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|)))/g,'<a style="color: rgb(0, 89, 255);" href="$1">$1</a>').replace(/([*].+?[*])/g, '<strong>$1</strong>').replace(/\*/g, '')}</pre>`;
    },
    currentNumber(number: number | string) {
      number = Number(number);
      if (this.course === COURSES_ENUM.ONE) return number;
      return this.numberWithSpaces(number.toFixed(0));
    },
    numberWithSpaces(x: string) {
      if (this.course === COURSES_ENUM.ONE) return x;
      if (!x) return x;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getTactics(type: string) {
      return this.target.portfolios[this.index][type].instruments;
    },
    sortInstrumentsSection(instruments: Array<Instrument.Dto>) {
      return instruments.sort((a, b) => {
        if (a.section_two > b.section_two) return 1;
        if (a.section_two < b.section_two) return -1;
        return 0;
      });
    },
    sortInstruments(type: string, instruments: Array<Instrument.Dto>) {
      if (type === 'stock') {
        return [
          ...this.sortInstrumentsSection(instruments.filter(i => i.instrument_type_two === 'Акции')),
          ...this.sortInstrumentsSection(instruments.filter(i => i.instrument_type_two !== 'Акции')),
        ];
      }
      if (type === 'bond') {
        const sorting = ['Вклады', 'Валютные счета', 'Деньги', 'Надёжные облигации', 'Облигации', 'ВДО', 'ETF и БПИФ на облигации'];
        let array: Array<Instrument.Dto> = [];
        for (const sort of sorting) {
          for (const instrument of instruments) {
            if (instrument.instrument_type_two === sort) array = [...array, instrument];
          }
        }
        for (const instrument of instruments) {
          if (!array.find(e => e.name === instrument.name)) array = [...array, instrument];
        }
        return array;
      }
      return instruments;
    },
    getCurrentInstruments(type: string) {
      if (this.course === COURSES_ENUM.TWO) {
        return this.sortInstruments(type, this.target.portfolios[this.index].core.instruments.filter((i: dynamicsObject) => i[`class_${this.course}_id`] === type));
      }
      return this.target.portfolios[this.index].core.instruments.filter((i: dynamicsObject) => i[`class_${this.course}_id`] === type);
    },
    getCurrentComment(type: string) {
      return this.target.portfolios[this.index].comments[type];
    }
  },
});
